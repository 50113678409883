/* Reset and Container Styling */
body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Arial', sans-serif;
    overflow: hidden;
  }
  
  .coming-soon-container {
    background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
    color: white;
    text-align: center;
    position: relative;
    overflow: hidden;
  }
  
  .coming-soon-content {
    animation: float 5s ease-in-out infinite;
  }
  
  /* Fade-in Animation */
  .fade-in {
    opacity: 0;
    animation: fadeIn 2s forwards;
  }
  
  .fade-in.delay-1 {
    animation-delay: 1s;
  }
  
  /* Floating Effect */
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  /* Fade-in Keyframes */
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Bubble Animation */
  .bubble-animation {
    position: absolute;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1) 10%, transparent 70%);
    z-index: 0;
    animation: bubbleMove 20s infinite linear;
  }
  
  /* Bubble Keyframes */
  @keyframes bubbleMove {
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(50%, 50%) scale(1.5);
    }
  }
  