/* Reset styling */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: 'Arial', sans-serif;
}

/* Container covers the full viewport */
.coming-soon-container {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  background-size: cover;
  color: white;
  text-align: center;
}

/* Timer styling */
.timer .time-box {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timer .time-value {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
  color: #fff;
}

.timer .time-label {
  font-size: 1rem;
  margin: 0;
  color: #ddd;
}

/* Full viewport */
.vh-100 {
  height: 100vh;
}

h1, p {
  margin: 0;
}

p.lead {
  margin-top: 10px;
  font-size: 1.25rem;
  color: #ddd;
}










.main-content{
  width:100%;
  min-height:300px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
